import React from "react";
import "./Myztro.scss";
import Myztro1 from "./Myztro1/Myztro1.jsx";

const apartemen = () => {
  return (
    <div id="Myztro" className="apartment">
      <div className="container-header1"> New Launching Myztro at Riverie</div>
      <div>
        <div className="Judul"></div>
        <Myztro1 />
      </div>
    </div>
  );
};

export default apartemen;
