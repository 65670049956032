import Myztro51 from '../../../media/Myztro/1 Lantai/1 Lantai (1).webp'
import Myztro52 from '../../../media/Myztro/1 Lantai/1 Lantai (2).webp'
import Myztro53 from '../../../media/Myztro/1 Lantai/1 Lantai (3).webp'
import Myztro54 from '../../../media/Myztro/1 Lantai/1 Lantai (4).webp'
import Myztro55 from '../../../media/Myztro/1 Lantai/1 Lantai (5).webp'

const Myztro5images =[
Myztro51,Myztro52,Myztro53,Myztro54, Myztro55,
]

export default Myztro5images;