import Sinsa1 from '../../../media/Sinsa/sinsa (1).webp'
import Sinsa2 from '../../../media/Sinsa/sinsa (2).webp'
import Sinsa3 from '../../../media/Sinsa/sinsa (3).webp'
import Sinsa4 from '../../../media/Sinsa/sinsa (4).webp'
import Sinsa5 from '../../../media/Sinsa/sinsa (5).webp'
import Sinsa6 from '../../../media/Sinsa/sinsa (6).webp'


const Sinsaimages =[
Sinsa1,Sinsa2,Sinsa3,Sinsa4,Sinsa5,Sinsa6
]

export default Sinsaimages;