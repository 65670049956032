import Myztro61 from '../../../media/Myztro/2 Lantai/2 Lantai (1).webp'
import Myztro62 from '../../../media/Myztro/2 Lantai/2 Lantai (2).webp'
import Myztro63 from '../../../media/Myztro/2 Lantai/2 Lantai (3).webp'
import Myztro64 from '../../../media/Myztro/2 Lantai/2 Lantai (4).webp'
import Myztro65 from '../../../media/Myztro/2 Lantai/2 Lantai (5).webp'
import Myztro66 from '../../../media/Myztro/2 Lantai/2 Lantai (6).webp'
import Myztro67 from '../../../media/Myztro/2 Lantai/2 Lantai (7).webp'
import Myztro68 from '../../../media/Myztro/2 Lantai/2 Lantai (8).webp'
import Myztro69 from '../../../media/Myztro/2 Lantai/2 Lantai (9).webp'
import Myztro610 from '../../../media/Myztro/2 Lantai/2 Lantai (10).webp'
import Myztro611 from '../../../media/Myztro/2 Lantai/2 Lantai (11).webp'


const Myztro6images =[
Myztro61,Myztro62,Myztro63,Myztro64,Myztro65,Myztro66,Myztro67,Myztro68,Myztro69,Myztro610,Myztro611
]

export default Myztro6images;