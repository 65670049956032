import React from "react";
import "./lokasi.scss";
import mapsimage from "../../media/Maps Desktop.png";
import mobilemaps from "../../media/Maps Mobile.webp";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faGraduationCap,
  faHouse,
  faMapPin,
} from "@fortawesome/free-solid-svg-icons";

const lokasi = () => {
  return (
    <div id="lokasistrategis" className="lokasi">
      <div className="judul">Location</div>
      <div className="maps-lokasi">
        <img className="img-lokasi-dekstop" src={mapsimage} alt="maps" />
        <img className="img-lokasi-mobile" src={mobilemaps} alt="maps" />
      </div>
      <div className="benefit-container">
        <div className="benefit-point">
          <div className="benefit-title">
            <FontAwesomeIcon icon={faGraduationCap} /> &nbsp;Education
          </div>
          <div className="benefit">
            <div className="point">
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;2 Menit ke Al-Hasra Islamic School
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;2 Menit ke UIN Syarif Hidayatullah University
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;5 Menit ke SMA Negeri 5 Depok
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;10 Menit ke Dwiwarna Highschool
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;15 Menit ke Pamulang University
            </div>
          </div>
        </div>
        <div className="benefit-point">
          <div className="benefit-title">
            <FontAwesomeIcon icon={faHouse} /> &nbsp;Health Care
          </div>
          <div className="benefit">
            <div className="point">
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;2 Menit ke Brawijaya Hospital
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;5 Menit ke Mitra Keluarga Hospital
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;5 Menit ke RSUD Depok
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;5 Menit ke Permata Hospital
            </div>
          </div>
        </div>
        <div className="benefit-point">
          <div className="benefit-title">
            <FontAwesomeIcon icon={faBuilding} /> &nbsp;Entertainment
          </div>
          <div className="benefit">
            <div className="point">
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;3 Menit ke The Park Mall
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;2 menit ke Ciplaz Parung
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;20 menit ke Bintaro Xchange
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;20 Menit ke Pondok Indah Mall
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default lokasi;
