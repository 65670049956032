import React from "react";
import "./footer.scss";
import logo from "../../media/logo.png";

const footer = () => {
  return (
    <div className="container-footer">
      <div className="container-atas">
        <div className="container-logo">
          <img className="gambar-logo" src={logo} alt="logo-Parkspring" />
        </div>
        <div className="container-deskripsi">
          <div className="alamat">
            <h1>Marketing Gallery</h1>
            <h2>Shila at Sawangan</h2>
            <h3>
              Jl. Raya Bojongsari No.53, Bojongsari Lama, Kec. Bojongsari, Kota
              Depok, Jawa Barat 16517
            </h3>
            <h4>Contact us : 628111800364</h4>
          </div>
          <div className="privasi"></div>
        </div>
      </div>
      <div className="container-bawah">© Shila at Sawangan</div>
    </div>
  );
};

export default footer;
