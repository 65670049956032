import React from "react";
import "./Produk2.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Hannamimages from "./Hannam.js";
import Porticoimages from "./Portico.js";
import Theoakwoodimages from "./Theoakwood.js";
import Tudorimages from "./Tudor.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faLocationDot,
  faMedal,
} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faBed} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

const Produk2 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const Theoakwoodwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=628111800364&text=Halo+Ferdy!%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(The%20Oakwood)%20https://marketing-shilasawangan.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Tudorwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=628111800364&text=Halo+Ferdy!%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Tudor)%20https://marketing-shilasawangan.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Hannamwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=628111800364&text=Halo+Ferdy!%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Hannam)%20https://marketing-shilasawangan.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Porticowa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=628111800364&text=Halo+Ferdy!%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Portico)%20www.marketing-shilaatsawangan.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="container">
      <div className="container-card3">
        <div className="card">
          <Slider {...settings}>
            {Theoakwoodimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">The Oakwood</div>
            <div className="gridmap">
              <FontAwesomeIcon className="icon" color="grey" icon={faMedal} />
              <div className="namalokasi">Free Biaya-biaya</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">13</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="#2b2b2b" icon={faArrowsUpDownLeftRight} />
            <span> 112m²</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faHouse} />
            <span> 136m²</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faBed} />
            <span> 3</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faShower} />
            <span> 3</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Theoakwoodwa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Tudorimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Tudor</div>
            <div className="gridmap">
              <FontAwesomeIcon className="icon" color="grey" icon={faMedal} />
              <div className="namalokasi">Free Biaya-biaya</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">16</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="#2b2b2b" icon={faArrowsUpDownLeftRight} />
            <span> 144m²</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faHouse} />
            <span> 179m²</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faBed} />
            <span> 4</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faShower} />
            <span> 4</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Tudorwa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Hannamimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Hannam</div>
            <div className="gridmap">
              <FontAwesomeIcon className="icon" color="grey" icon={faMedal} />
              <div className="namalokasi">Free Lift</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">17</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="#2b2b2b" icon={faArrowsUpDownLeftRight} />
            <span> 144m²</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faHouse} />
            <span> 206m²</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faBed} />
            <span> 5</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faShower} />
            <span> 6</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Hannamwa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Porticoimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Portico</div>
            <div className="gridmap">
              <FontAwesomeIcon className="icon" color="grey" icon={faMedal} />
              <div className="namalokasi">Siap Huni</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">19</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="#2b2b2b" icon={faArrowsUpDownLeftRight} />
            <span> 185m²</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faHouse} />
            <span> 189m²</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faBed} />
            <span> 4</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faShower} />
            <span> 4</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Porticowa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Produk2;
