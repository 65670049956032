import React from "react";
import "./Myztro1.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Myztro6images from "../../../media/Myztro/2 Lantai/M6";
import Myztro5images from "../../../media/Myztro/1 Lantai/M5";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faMedal,
} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faBed} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

const Produk1 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const whatsappMtownResidence = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=628111800364&text=Halo%20Ferdy,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Shila%20Sawangan%20(Myztro%206)%20https://marketing-shilasawangan.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const whatsappMtownSignature = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=628111800364&text=Halo%20Ferdy,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Shila%20Sawangan%20(Myztro%205)%20https://marketing-shilasawangan.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const whatsappRainbow = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285215127964&text=Halo%20Marketing%20Summarecon,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Apartemen%20Rainbow%20Springs%20https://marketinggadingserpong.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="container">
      <div className="containercard-apart">
        <div className="card">
          <Slider {...settings}>
            {Myztro5images.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Myztro 6</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="#C88C39"
                icon={faMedal}
              />
              <div className="namalokasi">Tanpa DP</div>
            </div>
          </div>
          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#1f565c" icon={faHouse} />
            <span> 60m²</span>
            <FontAwesomeIcon color="#1f565c" icon={faArrowsUpDownLeftRight} />
            <span> 36m²</span>
            <FontAwesomeIcon color="#1f565c" icon={faBed} />
            <span> 2</span>
            <FontAwesomeIcon color="#1f565c" icon={faShower} />
            <span> 1</span>
          </div>
          <hr />
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">4 Juta/</div>
              <div className="ket">Bulan</div>
            </div>
          </div>
          <div className="clusterbutton">
            <button
              onClick={whatsappMtownResidence}
              className="cluster-whatsapp">
              <FontAwesomeIcon size="lg" icon={faWhatsapp} />
              &nbsp;Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Myztro6images.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Myztro 5</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="#C88C39"
                icon={faMedal}
              />
              <div className="namalokasi">Tanpa DP</div>
            </div>
          </div>
          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#1f565c" icon={faHouse} />
            <span> 50m²</span>
            <FontAwesomeIcon color="#1f565c" icon={faArrowsUpDownLeftRight} />
            <span> 62m²</span>
            <FontAwesomeIcon color="#1f565c" icon={faBed} />
            <span> 2</span>
            <FontAwesomeIcon color="#1f565c" icon={faShower} />
            <span> 2</span>
          </div>
          <hr />
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">5 Juta/</div>
              <div className="ket">Bulan</div>
            </div>
          </div>
          <div className="clusterbutton">
            <button
              onClick={whatsappMtownSignature}
              className="cluster-whatsapp">
              <FontAwesomeIcon size="lg" icon={faWhatsapp} />
              &nbsp;Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Produk1;
