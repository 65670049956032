import React from "react";
import "./about.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import logoshila from "../../media/logo.png";

const aboutkbp = () => {
  const aboutwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=628111800364&text=Halo+Ferdy!%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20https://marketing-shilasawangan.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="aboutshila">
      <div id="about" className="container-aboutshila">
        <div className="heading-aboutshila">
          <div className="logoshila">
            <img className="gambarshila" src={logoshila} alt="logo-shila" />
          </div>
          <div className="desk">
            Shila at Sawangan dikembangkan oleh Vasanta Group, sebuah pengembang
            properti ternama di Indonesia. Vasanta Group dalam mengembangkan
            Shila at Sawangan dengan tujuan membuat kota mandiri dimana disana
            terdapat area residensial, area komersil, area perkantoran. Shila at
            Sawangan memiliki beberapa klaster, seperti The Grove, Laguna, dan
            Lake Vista, menjadikannya hunian yang asri dengan lingkungan yang
            hijau. Dalam pengembangan Shila at Sawangan, Vasanta Group
            menawarkan beberapa keunggulan, seperti hunian yang asri dengan
            lingkungan yang hijau, akses yang memadai dan lengkap, serta
            kualitas udara dan air yang baik. Shila at Sawangan menawarkan
            berbagai fasilitas yang dapat memenuhi kebutuhan penghuninya,
            seperti Sport Club, Jogging Track, Tennis Court, Olympic Size
            Swimming Pool, Smart security System. Kehadiran Kota Mandiri Shila
            at Sawangan tidak hanya menyediakan fasilitas yang lengkap, dengan
            masterplan yang matang dan terencana serta lokasi yang sangat
            strategis Shila at Sawangan mempunyai akses ke Tol Cijago
            (Cinere-Jagorawi), dan Tol JORR 2. Shila at Sawangan juga memiliki
            kawasan yang dekat dengan Area Pendidikan dan Kesehatan seperti, RS
            Brawijaya dan RS Mitra Keluarga.
          </div>
          <div className="button">
            <button className="wa" onClick={aboutwa}>
              <FontAwesomeIcon icon={faWhatsapp} />
              &nbsp; More Info
            </button>
          </div>
        </div>
        <div className="gambaraboutshila"></div>
      </div>
    </div>
  );
};

export default aboutkbp;
